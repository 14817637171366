
export const monthData = [
    {name: 'JANUARY', value: '01'},
    {name: 'FEBRUARY', value: '02'},
    {name: 'MARCH', value: '03'},
    {name: 'APRIL', value: '04'},
    {name: 'MAY', value: '05'},
    {name: 'JUNE', value: '06'},
    {name: 'JULY', value: '07'},
    {name: 'AUGUST', value: '08'},
    {name: 'SEPTEMBER', value: '09'},
    {name: 'OCTOBER', value: '10'},
    {name: 'NOVEMBER', value: '11'},
    {name: 'DECEMBER', value: '12'}
];

export const employeeTypeList = [
    {name: 'Permanent Manpower', value: 'Permanent Manpower'},
    {name: 'Permanent Blue Collar Manpower', value: 'Permanent Blue Collar Manpower'},
    {name: 'Permanent and Flexible Manpower', value: 'Permanent and Flexible Manpower'},
    {name: 'Flexible Manpower', value: 'Flexible Manpower'},
]


export const bdcreportfield = [
    { title: 'Remark', key: 'suggestion_bdc_id', type: 'text' },
    { title: 'Pers.No', key: 'emp_id', type: 'text' },
    { title: 'Wage Type', key: 'wage_type', type: 'text' },
    { title: 'Amount', key: 'amount', type: 'text' },
    { title: 'Date', key: 'created_date', type: 'date' },
    { title: 'Assignment', key: 'suggestion_year', type: 'text' },
]

export const suggestionReportFieldVisiblity = {
    S010:{showDivision:true,showToDate:false,showFromDate:false},
    D001:{showDivision:true,showToDate:false,showFromDate:false},
    S011:{showDivision:false,showToDate:false,showFromDate:false},
    S012:{showDivision:false,showToDate:false,showFromDate:false},
    S003:{showDivision:false,showToDate:false,showFromDate:false},
    S019:{showDivision:false,showToDate:true,showFromDate:true},
    S021:{showDivision:false,showToDate:true,showFromDate:true},
    S025:{showDivision:true,showToDate:false,showFromDate:false},
    // SA005:{showDivision:true,showToDate:false,showFromDate:false},
    // S004:{showDivision:true,showToDate:false,showFromDate:false},
}

export const suggestionBdcReport = 'Generate_BDC_Excel'



export const superAdminSuggestionReportFieldVisiblity = {
    SA001:{showToDate:true,showFromDate:true,showSuggStatus:true},
    SA002:{showToDate:true,showFromDate:true,showSuggStatus:true},
}

