import React, { Component } from 'react';
import './Appcenter.css';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import { connect } from 'react-redux';

const imgIcon = require('../../../../../assets/drishti_images/app_center/jigyasabot_icon.png')
class Appcenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            loading: false,
            urlData: '',
            appCenter: [
                { id: 1, src: require('../../../../../assets/drishti_images/app_center/accolades.svg'), title: 'Accolades', url: "https://tatamotors.vantagecircle.com/" },
                { id: 2, src: require('../../../../../assets/drishti_images/app_center/policies.svg'), title: 'Policies', url: "https://tatamotors.sharepoint.com/sites/c-app/s-MTMDev/SitePages/Policy1.aspx" },
                { id: 3, src: require('../../../../../assets/drishti_images/app_center/quest2travel.svg'), title: 'Quest2Travel', url: "https://tatamotors.quest2travel.in/mytmlsso" },
                { id: 4, src: require('../../../../../assets/drishti_images/app_center/mediassist.svg'), title: 'Mediassist', url: "https://portal.medibuddy.in/Home.aspx" },
                { id: 5, src: require('../../../../../assets/drishti_images/app_center/hxp_logo_final.png'), title: 'HxP - CareerXplore', url: "https://tatamotors-internal.turbohire.co/"},
                { id: 6, src: require('../../../../../assets/drishti_images/app_center/hxp_logo_final.png'), title: 'HxP - Referrals', url: "https://tatamotors-referral.turbohire.co/"},
                { id: 7, src: require('../../../../../assets/drishti_images/app_center/MyLxP_Icon.png'), title: 'MyLxP', url: "https://eu.degreed.com?orgsso=tatamotors" },
                { id: 8, src: require('../../../../../assets/drishti_images/app_center/learningCalendar.svg'), title: 'Learning Calendar', url: "https://tatamotors.sharepoint.com/sites/C-CorpHR/s-learningCalendar/SitePages/Learning.aspx?e=1%3Ac6edf0f0e80c41fd94546e3c01d9ebe0" },
                { id: 9, src: require('../../../../../assets/drishti_images/app_center/myhrportal.svg'), title: 'MyHR Portal', url: "https://performancemanager5.successfactors.eu/sf/home?company=tataindiaP&loginMethod=SSO" },
                { id: 10, src: require('../../../../../assets/drishti_images/app_center/myapps.svg'), title: 'MyApps', url: "https://mytatamotors.tatamotors.com/Dashboard/MyApps" },
                { id: 11, src: require('../../../../../assets/drishti_images/app_center/assistance.svg'), title: 'Counselling Services', url: "https://1to1help.net" },
                { id: 12, src: require('../../../../../assets/drishti_images/app_center/requestsystem.svg'), title: 'Request System', url: "https://gdcsupport.tatamotors.com/gdc_prd/#/login" },
            ]
        }
    }

    componentDidMount() {
        this.setState({
            username: this.props.loginData.userData.Perno
        })
        this.getJiygyasaBotData(); 
    }

    getJiygyasaBotData = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        fetchApiCall(Config.getJiygyasaBotUrl, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        urlData: res.data.data.url,
                        loading: false,
                    });

                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    render() {
        return (
            <div className="container-fluid">
                <LoaderIndicator loading={this.state.loading} />
                <div className="row">
                    <div className="app-center-card position-relative">
                        <div className="row">
                            <div className="d-flex justify-content-between card-title-position pb-0">
                                <div>
                                    <label className="edit-cardtitle">{'App Center'}</label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            {this.state.appCenter.map((list, id) =>
                                <div key={id} className="col-sm appcentercolumn">
                                    <div className="d-flex justify-content-center">
                                        <a href={list.url} target="_blank">
                                            <div className="inner-app">
                                                <img alt="Logo" src={list.src} className="appcenterlogo"></img>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="row appcenter-app-name mb-3">
                                        {list.title}
                                    </div>
                                </div>
                            )}
                            {/* ------------------Jygyasa bot----------------------------- */}
                            {/* {this.state.urlData &&
                                <div className="col-sm appcentercolumn">
                                    <div className="d-flex justify-content-center">
                                        <a href={this.state.urlData} target="_blank">
                                            <div className="inner-app">
                                                <img alt="Logo" src={imgIcon} className="appcenterlogo"></img>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="row appcenter-app-name mb-3">
                                        {'Jigyasa Bot'}
                                    </div>
                                </div>} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(Appcenter);

