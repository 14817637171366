import React, { Component } from 'react';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import plus from '../../../../assets/drishti_images/manager/landing/plus.svg';
import '../../employeeScreens/profile/ProfileScreen.css';
import Announcements from './AnnouncementsDashboard';
import PushNotifications from './PushNotificationsDashBoard';
import ScreenPopupNotifications from './ScreenPopupNotificationsDashBoard';
import AddDynamicImageDetails from '../adminNotificationHome/AddDynamicImageDetails';
import LeadershipAnnouncementDashboard from '../adminNotificationHome/LeadershipAnnouncementDashboard';
import TataBuzzDashboard from '../adminNotificationHome/TataBuzzDashboard';
import UpcomingOccasionsDashboard from '../adminNotificationHome/UpcomingOccasionsDashboard';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { currentDateYMD } from '../../../../utils/Utils';
import * as Config from '../../../../constants/Config';
import * as AdminLoginAction from '../login/LoginAction';
import * as KeycloakActions from '../../employeeScreens/login/KeyCloakAction';
import * as userActions from '../../employeeScreens/login/LoginAction';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import ConfirmPopups from './ConfirmPopups';

toast.configure();
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class AdminHomeScreen extends Component {
    constructor() {
        super();
        this.state = {
            admin: true,
            isAnnouncementsActive: true,
            isPushNotificationsActive: false,
            isScreenPopupNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isUpcomingOccasionsActive: false,

            loading: false,
            delete: false,
            show: false,
            selectedData: {},
            sliderImageData: [],
            deleteData: [],
            isAdmin: true,
        }
    }

    componentDidMount() {
        // this.getSliderImagesData()
    }

    toggleannouncement = () => {
        this.setState({ isAnnouncementsActive: true, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false })
    }
    togglepushnotification = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: true, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false })
    }
    togglescreenpopupnotification = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: true, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false })
    }
    toggleadddyanamicimages = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: true, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false },
            // () => this.getSliderImagesData()
        )
    }
    toggleLeadershipAnnouncement = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: true, isTataBuzzActive: false, isUpcomingOccasionsActive: false })
    }
    toggleTataBuzz = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: true, isUpcomingOccasionsActive: false })
    }
    toggleUpcomingOccasions = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: true })
    }
    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/home')
    }

    addAction = (type) => {
        console.log('addAction--->', type);
        this.setState({ show: true },
            () => console.log('add show---->', this.state.show)
        )
    }

    activeConfirmationPopup = (data) => {
        console.log('deletedata', data)
        this.setState({
            delete: !this.state.delete,
            deleteData: data
        },
            () => this.deleteSliderImageDetails()
        )
    }

    deleteSliderImageDetails = () => {
        this.setState({ loading: true, delete: false })
        const headers = { 'Content-Type': 'application/json' }
        let body = {
            image_title: this.state.deleteData.image_title,
        }
        fetchApiCall(Config.deleteSliderImage, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false }, () => this.getSliderImagesData())
                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    getActivePopupData() {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let request = { 'current_date': currentDateYMD(), is_admin: this.state.admin }



        fetchApiCall(Config.getActivePopup, request, headers, false, '')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    this.setState({ activeNotificationData: res.data.data ? res.data.data : [], loading: false })
                }
                else { this.setState({ activeNotificationData: [], loading: false }) }
            }).catch(err => {
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({ loading: false })
            })
    }

    deactivePopup = () => {
        this.setState({
            delete: false,
            notificationDeactivePopup: false,
            viewImageData: false,
            addImageData: false,
            addNotificationData: false,
            editNotificationData: false,
            addPushNotification: false,
            confirmPopup: false
        })
    }

    closing = () => {
        this.setState({ show: false })
    }

    render() {
        return (
            <div className="row p-12px">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 px-0 pt-0">
                    <div className='justify-content-between align-item-center'>
                        <div className='row px-0 pt-0' >
                            <img src={overviewButton} alt='' onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className='mt-1'>
                            <a
                                type="button"
                                className='btn btn-primary common-btn common-button-text d-flex justify-content-center w-13rem'
                                onClick={() => this.addAction('add', '')}
                            >
                                <img alt="plus" src={plus} className=" mx-1"></img>
                                ADD NOTIFICATION
                            </a>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='card-new col-md-12'>
                            <div className='row mt-3 px-3 ss col-md-12'>
                                <div className={this.state.isAnnouncementsActive ? "tab-active" : "tab-inactive"} onClick={this.toggleannouncement}>
                                    Announcements
                                </div>
                                <div className={this.state.isPushNotificationsActive ? "tab-active" : "tab-inactive"} onClick={this.togglepushnotification}>
                                    Push Notifications
                                </div>
                                <div className={this.state.isScreenPopupNotificationsActive ? "tab-active" : "tab-inactive"} onClick={this.togglescreenpopupnotification}>
                                    Screen Pop-up Notifications
                                </div>
                                <div className={this.state.isAddDynamicImageDetailsActive ? "tab-active" : "tab-inactive"} onClick={this.toggleadddyanamicimages}>
                                    Log-In Images
                                </div>
                                <div className={this.state.isLeadershipAnnouncementActive ? "tab-active" : "tab-inactive"} onClick={this.toggleLeadershipAnnouncement}>
                                    Leadership Announcement
                                </div>
                                <div className={this.state.isTataBuzzActive ? "tab-active" : "tab-inactive"} onClick={this.toggleTataBuzz}>
                                    Tata Buzz News
                                </div>
                                <div className={this.state.isUpcomingOccasionsActive ? "tab-active" : "tab-inactive"} onClick={this.toggleUpcomingOccasions}>
                                    Upcoming Occasions
                                </div>
                            </div>
                            <div className='hz-lines mr-4 ml-4'></div>
                            <div className='profile-inner-card'>
                                {this.state.isAnnouncementsActive ?
                                    <div>
                                        <Announcements
                                            data={this.state.notificationlist}
                                            isEdit={this.state.editNotificationData}
                                            updateData={() => this.getActivePopupData()}
                                            onDismiss={this.deactivePopup}
                                        />
                                    </div> : null
                                }
                                {this.state.isPushNotificationsActive ?
                                    <div>
                                        <PushNotifications
                                            isPushNotificationsActive={this.state.isPushNotificationsActive}
                                        />
                                    </div> : null
                                }
                                {this.state.isScreenPopupNotificationsActive ?
                                    <div>
                                        <ScreenPopupNotifications />
                                    </div> : null
                                }
                                {this.state.isAddDynamicImageDetailsActive ?
                                    <div>
                                        <AddDynamicImageDetails />
                                    </div> : null
                                }
                                {this.state.isLeadershipAnnouncementActive ?
                                    <div>
                                        <LeadershipAnnouncementDashboard />
                                    </div> : null
                                }
                                {this.state.isTataBuzzActive ?
                                    <div>
                                        <TataBuzzDashboard />
                                    </div> : null
                                }
                                {this.state.isUpcomingOccasionsActive ?
                                    <div>
                                        <UpcomingOccasionsDashboard />
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.show &&
                    <ConfirmPopups
                        loginData={this.props.loginData}
                        closeFirstPopup={() => this.setState({ show: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
        tokenActions: bindActionCreators(KeycloakActions, dispatch),
        actions: bindActionCreators(userActions, dispatch),
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(AdminHomeScreen);