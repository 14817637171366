import React, { useEffect, useState } from "react";
import DynamicDropdown from "../../../../shared/components/DynamicDropdown";
import {exportAllData,csvToExcel, exportToExcel, toaster } from "../../../../../utils/Utils";
import {employeeTypeList,monthData, suggestionReportFieldVisiblity } from "./constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Select, { components } from 'react-select';
import moment from "moment";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import {
    getMangerReportList,
    getDivisionalAgencyDashboard,
    get_agewise_pending_report,
    get_SuggOffice_Suggestion_generation_and_acceptance_report,
    getMangerSuggEvaluationDetailReport,
    getMangerSuggEvaluationSummaryReport,
    get_agency,
    getMasterStatus,
    getCostCenterSummaryReport,
    getManagerFinancialYears,
    getCostCenterDetailReport,
    get_Non_participant_reports,
    get_Suggestion_generation_reports,
    getEvaluatorAgencyDashboard,
    getMangerSuggestionPerformanceReportDivc,
    getStatusWiseSuggReport,
    getStatusWiseDetailReport
} from "../../../../../constants/api.service";
import LargeDropdown from "../../../../shared/components/LargeDropdown";
import DateFilter from "../../../adminScreens/clearance/DateFilter";
import * as XLSX from 'xlsx';

function SuggestionSchemeReport() {
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [loader, setLoader] = useState(false);
  const [years, setYears] = useState(null);
  const [reportType, setReportType] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [showDivison,setShowDivison] = useState(false);
  const [showToDate,setshowToDate] = useState(false);
  const [showFromDate,setShowFromDate] = useState(false);
  const [division,setDivision] = useState(null);
  const [toDate,setToDate] = useState(null);
  const [fromDate,setFromDate] = useState(null);
  const [divisionData,setDivisionData] = useState([]);
  const [divisionOrgData,setDivisionOrgData] = useState([]);
  const [showReportStatus,setShowReportStatus] = useState(false);
  const loginData = useSelector(state => state.loginState);
  const today = new Date().toISOString().split("T")[0];
  const currentMonthIndex = new Date().getMonth(); 
  const currentMonth = monthData[currentMonthIndex];
  const [agencyData, setAgencyData] = useState([]);
  const [agency, setAgency] = useState('');
  const [showAgency,setShowAgency] = useState(false);
  const [currentSuggStatusCodes,setCurrentSuggStatusCodes] = useState(null);
  const [statusFilter,setStatusFilter] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [selectedEmployeeType, setSelectedEmployeeType] = useState("");
  const [agencyList,setAgencyList] =useState("")
  const currentYear = new Date().getFullYear();
  const suggestionData = useSelector((state) => state.applicableSuggestionReducerState);

  const is_sug_eval_applicable = suggestionData?.isApplicableForSuggestion?.is_sug_eval_applicable
  const is_sug_div_applicable = suggestionData?.isApplicableForSuggestion?.is_sug_div_applicable
  
  

  const reportTypeSelected = (data) => {
    setSelectedReportType(data);
    if(data?.id === 'D001'){
      setDivisionData(divisionOrgData);
    }else{
      const allStatus = { id: 0 , label: 'All'};
      setDivisionData([allStatus, ...divisionOrgData]);
    }
    const filterData = suggestionReportFieldVisiblity[data?.id]
    if(filterData){
      setShowDivison(filterData.showDivision);
      setshowToDate(filterData.showToDate);
      setShowFromDate(filterData.showFromDate);
      setShowReportStatus(filterData.showSuggStatus)
    }
    setAgency('');
    setShowAgency(false)
    setFromDate('');
    setToDate('');
    setDivision('')
    setAgency('');
    setSelectedEmployeeType('');
    setSelectedMonth(currentMonth);
    setAgencyData([]);
    setStatusFilter('')
    setSelectedYear(years[0]);
  };

  const handleEmployeType = (data) => {
    setSelectedEmployeeType(data);
    // validate('ListScheme', data);
  };
  const yearSelected = (data) => {
    setSelectedYear(data);
  };
  const monthSelected = (data) => {
    setSelectedMonth(data);
  };
  const handleDivision = (data) => {
    setDivision(data);
    if(selectedReportType?.id === "D005" || selectedReportType?.id === "D006" || selectedReportType?.id === "D001"){
      setShowAgency(false);
    }else{

      setShowAgency(true);
    }
    setAgency('');
    setAgencyData([]);
    data.id == 0 ?  setShowAgency(false) :  getAgency(data.id);
  };

  const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '300px',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'black',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#653DAF',
        borderRadius: '5px',
        padding: '2px 5px',
        color: 'white'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'white',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer',
        color: 'white',
        ':hover': {
            backgroundColor: 'transparent',
            color: 'white',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        maxHeight: '200px',
        overflowY: 'auto',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        color: state.isSelected ? 'white' : provided.color,
        padding: '10px',
        margin: '5px 0',
        cursor: 'pointer',
        ':active': {
            backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: '38px',
        overflowY: 'auto',
    }),
  };


  const getEvaluatorAgencyDropDown = () => {
    let params = {
      comp_code: loginData.userData.CompCode,
      pa: loginData.userData.PersArea,
    };
    getEvaluatorAgencyDashboard(params)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const agency_list = res?.data?.data?.map(agency => ({
            id: agency?.agency_id,
            label: agency?.agency_name
        }));
        const allAgency = {id:'all', label: 'All' };
        setAgencyList([allAgency, ...agency_list]);
        } else {
          toaster("warning", res?.data?.message ?? "Something Went Wrong!");
        }
      })
      .catch((error) => {
        toaster("warning", error?.data?.message ?? "Something Went Wrong");
      })
  };

  //-------------------------Get suggestion master status office---------------------//
  const getStatusMasterData = () => {
    setLoader(true);
    getMasterStatus()
        .then(res => {
            if (res.status === 200) {
                const formattedStatusList = res.data.data.status_list.map(status => ({
                    label: status.status_name,
                    value: status.status_code,
                }));
                const allStatus = { label: 'All', value: 'all' };
                setStatusList([allStatus, ...formattedStatusList]);
            } else {
                toast.warn(res.message, {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    className: 'toastWarn',
                });
            }
        })
        .catch(error => {
            toast.warn(error.message, {
                position: 'bottom-center',
                hideProgressBar: true,
                className: 'toastWarn',
            });
        })
        .finally(() => {
          setLoader(false);
        });
  };
  // ---------------------Get Suggestion NonParticipant Report------------ //
  const getNonParticipantReportData = () => {
    setLoader(true);
    const param = {
      financial_year: selectedYear?.value,
      comp_code: loginData?.userData?.CompCode,
      pa: loginData?.userData?.PersArea,
      role: (is_sug_div_applicable && is_sug_eval_applicable) 
      ? "Division_Coordinator"
      : (!is_sug_eval_applicable && is_sug_div_applicable) 
        ? "Division_Coordinator"
        : (is_sug_eval_applicable && !is_sug_div_applicable) 
          ? "Evaluator"
            : (!is_sug_eval_applicable && !is_sug_div_applicable)
            ? "Suggestor" 
              : "",
    };
    get_Non_participant_reports(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Non_Participation_Report.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
          setLoader(false);
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Suggestion Generation Report------------ //
  const getSuggestiongenerationReport = () => {
    setLoader(true);
    const param = {
      from_date: fromDate,
      to_date: toDate,
      comp_code: loginData?.userData?.CompCode,
      pa: loginData?.userData?.PersArea,
      role: (is_sug_div_applicable && is_sug_eval_applicable) 
      ? "Division_Coordinator"
      : (!is_sug_eval_applicable && is_sug_div_applicable) 
        ? "Division_Coordinator"
        : (is_sug_eval_applicable && !is_sug_div_applicable) 
          ? "Evaluator"
            : (!is_sug_eval_applicable && !is_sug_div_applicable)
            ? "Suggestor" 
              : "",
    };
    get_Suggestion_generation_reports(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
          // const resultSet = res?.data?.data;
          // setSuggestionGenerationReport(resultSet);
          // exportAllData(resultSet, "Suggestion_Generation_Report");
          const contentDisposition = res.headers['content-disposition'];
          let filename = 'Suggestion_Generation_Report.xlsx'; 
          csvToExcel(url,contentDisposition,filename)
          setLoader(false);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };



  // ---------------------Get Report List Details------------ //
  const getReportListDetail = () => {
    setLoader(true);
    const param = {
      sug_role: (is_sug_div_applicable && is_sug_eval_applicable) 
      ? "Division_Coordinator,Evaluator"
      : (!is_sug_eval_applicable && is_sug_div_applicable) 
        ? "Division_Coordinator"
        : (is_sug_eval_applicable && !is_sug_div_applicable) 
          ? "Evaluator"
            : (!is_sug_eval_applicable && !is_sug_div_applicable)
            ? "Suggestor" 
              : "",
    };

    getMangerReportList(param)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const reportList = res?.data?.data?.map((item) => ({
            name: item.report_name,
            id: item.report_code,
          }));
          setReportType(reportList);
        } else {
          setReportType([]);
          res?.status !== 404 && toaster("warning", res?.data?.message ?? "someThingWentWrong")
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Financial Years Details------------ //
  const getFinancialYearsDetailManager = () => {
    setLoader(true);
    getManagerFinancialYears()
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const yearList = res?.data?.data.map((item) => ({
            name: item?.fy_year,
            value: item?.value,
          }));
          setYears(yearList);
          const initialSelectedYear = yearList.find((year) => year.value === currentYear);
          if (initialSelectedYear) {
            setSelectedYear(initialSelectedYear);
          } else {
            setSelectedYear(yearList[0]);
          }
          
        } else {
          res?.status !== 404 && toaster("warning", res?.data?.message ?? "someThingWentWrong")
          setYears(null);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };
  // ---------------------Get Division Details------------ //
  const getDivision = () => {
    setLoader(true);
    const param = {
      comp_code: loginData?.userData?.CompCode,
      pa: loginData?.userData?.PersArea,
    };
    getDivisionalAgencyDashboard(param)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const division = res?.data?.data?.map((division) => ({
            id: division?.division_code,
            label: division?.division_name,
          }));
          const allStatus = { id: 0 , label: 'All'};
          setDivisionData([allStatus, ...division]);
          setDivisionOrgData(division);
          // setDivisionData(division);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
          setDivisionData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Cost Center Summary Report------------ //
  const getCostCenterSumReport = () => {
    setLoader(true);
    const param = {
      comp_code: loginData?.userData?.CompCode,
      pa: loginData?.userData?.PersArea,
      financial_year:selectedYear?.value,
      from_date: fromDate,
      to_date: toDate,
      division_codes: division?.id === 0 ? 'all' : division?.id,
      role: (is_sug_div_applicable && is_sug_eval_applicable) || (!is_sug_eval_applicable && is_sug_div_applicable) ? "Division_Coordinator" :  ""
    }
    getCostCenterSummaryReport(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          
            const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Cost_Center_Summary_Report.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
    }

  // ---------------------Get Cost Center Detail Report------------ //
  const getCostCenterDetailsReport = () => {
    setLoader(true);
    const param = {
      month: parseInt(selectedMonth?.value),
      comp_code: loginData?.userData?.CompCode,
      pa: loginData?.userData?.PersArea,
      year:selectedYear?.value,
      division_codes: division?.id === 0 ? 'all' : division?.id,
      role: (is_sug_div_applicable && is_sug_eval_applicable) || (!is_sug_eval_applicable && is_sug_div_applicable) ? "Division_Coordinator" :  "",
      emp_type: selectedEmployeeType?.value
    }
    getCostCenterDetailReport(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Cost_Center_Detail_Report.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
    }
  
  // ---------------------Get Suggestion Evaluation Detail Report------------ //
  const getSuggestionEvaluationDetailReport = () => {
    setLoader(true);
    const param = {
      comp_code:loginData?.userData?.CompCode,
      // division_code: division?.id ? division?.id : "",
      pa:loginData?.userData?.PersArea,
      from_date: fromDate,
      to_date: toDate,
      // agency: agency?.id,
      division_codes:division?.id === 0 ? 'all' : division?.id,
      agency_codes:agency?.id === 'all' ? 'all' : agency?.id,
      status_codes:currentSuggStatusCodes,
      role:(is_sug_div_applicable && is_sug_eval_applicable) 
      ? "Division_Coordinator"
      : (!is_sug_eval_applicable && is_sug_div_applicable) 
        ? "Division_Coordinator"
        : (is_sug_eval_applicable && !is_sug_div_applicable) 
          ? "Evaluator"
            : (!is_sug_eval_applicable && !is_sug_div_applicable)
            ? "Suggestor" 
              : "",
    };
    getMangerSuggEvaluationDetailReport(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Sugg_Evaluation_Detail_report.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
      // setLoader(false);
      // toast.success('The report will be sent to your email shortly', {
      //   position: "bottom-center",
      //   hideProgressBar: true,
      //   className: 'toastSuccess'
      // });
  };  

  // ---------------------Get Suggestion Performance Report DIVC------------ //
  const getSuggestionPerformanceReportDivc = () => {
    setLoader(true);
    const param = {
      comp_code:loginData?.userData?.CompCode,
      pa:loginData?.userData?.PersArea,
      from_date: fromDate,
      to_date: toDate,
      division_codes:division?.id === 0 ? 'all' : division?.id,
      agency_codes:agency?.id === 'all' ? 'all' : agency?.id,
      role:'Division_Coordinator'
     
    };
    getMangerSuggestionPerformanceReportDivc(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Sugg_Performance_Report_DIVC.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });}
  
  // ---------------------Get Suggestion Evaluation Summary Report------------ //
  const getSuggestionEvaluationSummaryReport = () => {
    setLoader(true);
    const param = {
      comp_code:loginData?.userData?.CompCode,
      // division_code: division?.id ? division?.id : "",
      pa:loginData?.userData?.PersArea,
      from_date: fromDate,
      to_date: toDate,
      // division_codes:division?.id,
      division_codes:division?.id === 0 ? 'all' : division?.id,
      agency_codes:agency?.id === 'all' ? 'all' : agency?.id,
      // status_codes:currentSuggStatusCodes,
      role:(is_sug_div_applicable && is_sug_eval_applicable) 
      ? "Division_Coordinator"
      : (!is_sug_eval_applicable && is_sug_div_applicable) 
        ? "Division_Coordinator"
        : (is_sug_eval_applicable && !is_sug_div_applicable) 
          ? "Evaluator"
            : (!is_sug_eval_applicable && !is_sug_div_applicable)
            ? "Suggestor" 
              : "",
     
    };
    getMangerSuggEvaluationSummaryReport(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Sugg_Evaluation_Summary_report.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  }; 

  const handleStatusFilter =(selectedOptions) => {
    setStatusFilter(selectedOptions);
    const containsAll = selectedOptions.some(obj => Object.values(obj).includes("All"));
    if(containsAll){
      setCurrentSuggStatusCodes('all');
      return;
    }
    const selectedValues = selectedOptions.map(option => option.value).join(',');
    setCurrentSuggStatusCodes(selectedValues);
  };

  // ---------------------Get Agency Details Based of Division ID------------ //
  const getAgency = (divisionId) => {
    setLoader(true);
    const params = { division: divisionId };
    get_agency(params)
        .then(res => {
            if (res?.status === 200) {
                const agency_list = res?.data?.data?.agency_list.map(agency => ({
                    id: agency?.agency_code,
                    label: agency?.agency_name
                }));
                const allAgency = {id:'all', label: 'All' };
                setAgencyData([allAgency, ...agency_list]);
            } else {setAgencyData([]);}
        })
        .catch(errors => {
            toast.warn(errors, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        })
        .finally(() => {setLoader(false);});
};

  useEffect(() => {
    getReportListDetail();
    getDivision();
    getFinancialYearsDetailManager();
    getEvaluatorAgencyDropDown()
    setSelectedMonth(currentMonth);
    getStatusMasterData();
  }, []);

  // ---------------------Get Age Wise Pending Report------------ //
  const getAgewisePendingReport = async()=>{
    try {
      setLoader(true);
      const param = {
          division_code : division?.id === 0 ? 'all' : division?.id,
          role:(is_sug_div_applicable && is_sug_eval_applicable) 
          ? "Division_Coordinator"
          : (!is_sug_eval_applicable && is_sug_div_applicable) 
            ? "Division_Coordinator"
            : (is_sug_eval_applicable && !is_sug_div_applicable) 
              ? "Evaluator"
                : (!is_sug_eval_applicable && !is_sug_div_applicable)
                ? "Suggestor" 
                  : "",
      };
      const res = await get_agewise_pending_report(param);
      if (res?.status == 200 || res?.status == 201) {
        const url = URL.createObjectURL(res?.data);
        const contentDisposition = res.headers['content-disposition'];
        let filename = 'Age_Wise_Pending_Report.xlsx'; 
        csvToExcel(url,contentDisposition,filename)}
        else{
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
  } catch (errors) {
      toast.warn(errors.message || "An error occurred.", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
      });
  } finally {
      setLoader(false);
  }
  }

  // ---------------------Get Suggestion Generation Acceptance Report------------ //
  const getSuggestiongenerationandacceptance_report = async () => {
    setLoader(true);
    const param = {
      // division_code : division?.id,
      comp_code: loginData.userData.CompCode,
      pa: loginData.userData.PersArea,
      from_date: fromDate,
      to_date: toDate,
      role: (is_sug_div_applicable && is_sug_eval_applicable) 
      ? "Division_Coordinator"
      : (!is_sug_eval_applicable && is_sug_div_applicable) 
        ? "Division_Coordinator"
        : (is_sug_eval_applicable && !is_sug_div_applicable) 
          ? "Evaluator"
            : (!is_sug_eval_applicable && !is_sug_div_applicable)
            ? "Suggestor" 
              : "",
    };
    await get_SuggOffice_Suggestion_generation_and_acceptance_report(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Sugg_Gen_&_Acceptance_Report.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
          setLoader(false);
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Status Wise Suggestion Report------------ //
  const getStatusWiseSuggestionReport = async () => {
    setLoader(true);
    const param = {
      from_date: fromDate,
      to_date: toDate,
      comp_code: loginData.userData.CompCode,
      pa: loginData.userData.PersArea,
      role: (is_sug_div_applicable && is_sug_eval_applicable) 
      ? "Division_Coordinator"
      : (!is_sug_eval_applicable && is_sug_div_applicable) 
        ? "Division_Coordinator"
        : (is_sug_eval_applicable && !is_sug_div_applicable) 
          ? "Evaluator"
            : (!is_sug_eval_applicable && !is_sug_div_applicable)
            ? "Suggestor" 
              : "",
    };
    await getStatusWiseSuggReport(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Status_Wise_Suggestion_Report.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
          setLoader(false);
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };


  // ---------------------Get Status Wise Detail Report------------ //
  const getStatusWiseDetailReportEmp = async () => {
    setLoader(true);
    const param = {
      from_date: fromDate,
      to_date: toDate,
      comp_code: loginData.userData.CompCode,
      pa: loginData.userData.PersArea,
      role: (is_sug_div_applicable && is_sug_eval_applicable) 
      ? "Division_Coordinator"
      : (!is_sug_eval_applicable && is_sug_div_applicable) 
        ? "Division_Coordinator"
        : (is_sug_eval_applicable && !is_sug_div_applicable) 
          ? "Evaluator"
            : (!is_sug_eval_applicable && !is_sug_div_applicable)
            ? "Suggestor" 
              : "",
    };
    await getStatusWiseDetailReport(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'Status_Wise_Detail_Report.xlsx'; 
            csvToExcel(url,contentDisposition,filename)
          setLoader(false);
        } else {
          toast.warn("No Data Found", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };
  
  
  const downloadReport = () => {
    if (selectedReportType?.id === "D002") {
      if(toDate && fromDate && division && (division?.id === 0 ? true : !!agency?.label) && currentSuggStatusCodes){
        getSuggestionEvaluationDetailReport();
      }
      
    }
    if (selectedReportType?.id === "D001") {
      if(division){
        getAgewisePendingReport();
      }
    }
    if (selectedReportType?.id === "D003") {
      if(toDate && fromDate && division && (division?.id === 0 ? true : !!agency?.label)){
        getSuggestionEvaluationSummaryReport();
      }
    }
    if (selectedReportType?.id === "D010") {
      if(toDate && fromDate && division && (division?.id === 0 ? true : !!agency?.label)){
        getSuggestionPerformanceReportDivc();
      }
    }
    if (selectedReportType?.id === "E004") {
      if(toDate && fromDate && agency){
        getSuggestionEvaluationSummaryReport();
      }
    }
    if (selectedReportType?.id === "D004" || selectedReportType?.id === "E003") {
      if(toDate && fromDate){
        getSuggestiongenerationandacceptance_report()
      }
    }
    if (selectedReportType?.id === "D005") {
      if( selectedYear && toDate && fromDate  && division){
        getCostCenterSumReport()
      }
    }
    if (selectedReportType?.id === "D008" ) {
      if(toDate && fromDate){
        getSuggestiongenerationReport()
      }
    }
    if (selectedReportType?.id === "E002") {
      if(toDate && fromDate){
        getSuggestiongenerationReport()
      }
    }
    if (selectedReportType?.id === "E005") {
      if(toDate && fromDate){
        getStatusWiseSuggestionReport()
      }
    }
    if (selectedReportType?.id === "E006") {
      if(toDate && fromDate){
        getStatusWiseDetailReportEmp()
      }
    }
    if (selectedReportType?.id === "D011") {
      if(toDate && fromDate){
        getStatusWiseSuggestionReport()
      }
    }
    if (selectedReportType?.id === "D012") {
      if(toDate && fromDate){
        getStatusWiseDetailReportEmp()
      }
    }
    if (selectedReportType?.id === "SU01") {
      if(toDate && fromDate){
        getStatusWiseSuggestionReport()
      }
    }
    if (selectedReportType?.id === "SU02") {
      if(toDate && fromDate){
        getStatusWiseDetailReportEmp()
      }
    }
    if (selectedReportType?.id === "D006") {
      if(selectedMonth && selectedYear && selectedEmployeeType && division){
        getCostCenterDetailsReport()
      }
    }
    if (selectedReportType?.id === "D007") {
      if(selectedYear){
        getNonParticipantReportData()
      }
    }
    if (selectedReportType?.id === "E001") {
      if(selectedYear){
        getNonParticipantReportData()
      }
    }
  }

  const handleAgency = (data) => {

    setAgency(data)
    // validate('agency', data);
  }

  const checkValidity = () => {
    if (selectedReportType?.id === "D002") {
      if(toDate && fromDate && division && (division?.id === 0 ? true : !!agency?.label) && currentSuggStatusCodes){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D001") {
      if(division){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D003") {
      if(toDate && fromDate && division && (division?.id === 0 ? true : !!agency?.label)){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D010") {
      if(toDate && fromDate && division && (division?.id === 0 ? true : !!agency?.label)){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "E004") {
      if(toDate && fromDate && agency){
        return false;
      } else {
        return true;
      }
    }

    if (selectedReportType?.id === "D004") {
      if(toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "E003") {
      if(toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D005") {
      if(selectedYear && toDate && fromDate && division){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D008") {
      if( toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "E002") {
      if( toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "E005") {
      if( toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "E006") {
      if( toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D011") {
      if( toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D012") {
      if( toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "SU02") {
      if( toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "SU01") {
      if( toDate && fromDate){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D006") {
      if(selectedMonth && selectedYear && selectedEmployeeType && division){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "D007") {
      if(selectedYear){
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "E001") {
      if(selectedYear){
        return false;
      } else {
        return true;
      }
    }

    return true;
  }

  return (
    <div>
      <LoaderIndicator loading={loader} />
      <div className="ml-10 h-90">
        <div>
          <div className="gap-20">
            <div className="d-flex flex-direction-column">
              <div className="flex-direction-row flex-wrap justify-content-between align-item-center filter">
                <div className="d-flex flex-wrap flex-direction-row mx-2 align-items-center">
                  <div className="flex-direction-column ml-3">
                    <div className="p-sub-heading ml-2">
                      {"Select Report Type"}<span style={{color: '#FF4C4C'}}>*</span>
                    </div>
                    <DynamicDropdown
                      data={reportType}
                      render="name"
                      value={selectedReportType?.name}
                      selectedValue={(data) => reportTypeSelected(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>

                  {selectedReportType &&
                  (selectedReportType?.id === "D005" || selectedReportType?.id === "D006" || selectedReportType?.id === "E001"  || selectedReportType?.id === "D007") &&
                  selectedReportType?.id !== "D004" &&
                  selectedReportType?.id !== "E003" &&
                  selectedReportType?.id !== "D002" &&
                  selectedReportType?.id !== "D001" &&
                  selectedReportType?.id !== "D003" &&
                  selectedReportType?.id !== "E004" &&
                  selectedReportType?.id !== "D010" ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">{"Select Financial Year"}<span style={{color: '#FF4C4C'}}>*</span></div>
                      <DynamicDropdown
                        data={years}
                        render="name"
                        value={
                          selectedYear?.name ? selectedYear?.name : "Select Year"
                        }
                        selectedValue={(data) => yearSelected(data)}
                        arrow={"true"}
                        height={"NotificationHeight"}
                        width={"w-8rem"}
                      />
                    </div>
                  ) : null}

{selectedReportType &&
                  (selectedReportType?.id === "D006") &&
                  selectedReportType?.id !== "D004" &&
                  selectedReportType?.id !== "E003" &&
                  selectedReportType?.id !== "D002" &&
                  selectedReportType?.id !== "D001" &&
                  selectedReportType?.id !== "D003" &&
                  selectedReportType?.id !== "E004" &&
                  selectedReportType?.id !== "D010" ?  (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">{"Select Month"}<span style={{color: '#FF4C4C'}}>*</span></div>
                      <DynamicDropdown
                        data={monthData}
                        render="name"
                        value={selectedMonth?.name ? selectedMonth?.name : "Select Month"}
                        selectedValue={(data) => monthSelected(data)}
                        arrow={"true"}
                        height={"NotificationHeight"}
                        width={"w-8rem"}
                      />
                    </div>
                  ) : null}

                  {selectedReportType &&
                  (selectedReportType?.id === "D006") &&
                  selectedReportType?.id !== "D004" &&
                  selectedReportType?.id !== "E003" &&
                  selectedReportType?.id !== "D005" &&
                  selectedReportType?.id !== "D002" &&
                  selectedReportType?.id !== "D001" &&
                  selectedReportType?.id !== "D003" &&
                  selectedReportType?.id !== "E004" &&
                  selectedReportType?.id !== "D010" ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">
                        {"Select Employee Type"}<span style={{color: '#FF4C4C'}}>*</span>
                      </div>
                      <DynamicDropdown
                        data={employeeTypeList}
                        render="name"
                        value={selectedEmployeeType?.name}
                        selectedValue={(data) => handleEmployeType(data)}
                        arrow={"true"}
                        height={"NotificationHeight"}
                        width={"w-20rem"}
                      />
                    </div>
                  ) : null}

                  {
                    showToDate && (selectedReportType?.id === "E004" ||selectedReportType?.id === "D010" || selectedReportType?.id === "D003"|| selectedReportType?.id === "D005" || selectedReportType?.id === "D008" || selectedReportType?.id === "E002" || selectedReportType?.id === "D002" || selectedReportType?.id === "D004" || selectedReportType?.id === "E003" || selectedReportType?.id === "E005" || selectedReportType?.id === "E006" || selectedReportType?.id === "D011" || selectedReportType?.id === "D012" || selectedReportType?.id === "SU01" ||selectedReportType?.id === "SU02") &&    
                    selectedReportType?.id !== "D001"  ?
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-25px">
                        {"From Date"}<span style={{color: '#FF4C4C'}}>*</span>
                      </div>
                      <DateFilter
                          title=""
                          action={(val) => {setFromDate(val);setToDate('')}}
                          value={fromDate}
                          maxDate={moment(new Date()).format("YYYY-MM-DD")}
                          minDate={moment("2024-08-01").format("YYYY-MM-DD")}
                        />
                    </div>
                    :
                    null
                  }

                  {
                    showFromDate && (selectedReportType?.id === "E004" || selectedReportType?.id === "D010" || selectedReportType?.id === "D003"|| selectedReportType?.id === "D005" || selectedReportType?.id === "D008" || selectedReportType?.id === "E002" || selectedReportType?.id === "D002" || selectedReportType?.id === "D004" || selectedReportType?.id === "E003" || selectedReportType?.id === "E005" || selectedReportType?.id === "E006" || selectedReportType?.id === "D011" || selectedReportType?.id === "D012" || selectedReportType?.id === "SU01" || selectedReportType?.id === "SU02")  &&
                    selectedReportType?.id !== "D001" ?
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-25px">
                        {"To Date"} <span style={{color: '#FF4C4C'}}>*</span>
                      </div>
                      <DateFilter
                        title=""
                        action={(val) => {setToDate(val)} }
                        value={toDate}
                        maxDate={selectedReportType?.id === "SU02" || selectedReportType?.id === "D012" || selectedReportType?.id === "E006" || selectedReportType?.id === "D006" || selectedReportType?.id === "D002"
                          ? moment(fromDate).add(3, 'months').isAfter(moment()) 
                            ? moment().format("YYYY-MM-DD") 
                            : moment(fromDate).add(3, 'months').format("YYYY-MM-DD")
                          : selectedReportType?.id === "D010" || selectedReportType?.id === "SU01" || selectedReportType?.id === "D011" || selectedReportType?.id === "E005" || selectedReportType?.id === "D005" || selectedReportType?.id === "D003" || selectedReportType?.id === "E004"
                          ? moment(fromDate).add(12, 'months').isAfter(moment()) 
                            ? moment().format("YYYY-MM-DD") 
                            : moment(fromDate).add(12, 'months').format("YYYY-MM-DD")
                          : moment().format("YYYY-MM-DD")}
                        minDate={fromDate}
                      />
                    </div>
                    :
                    null

                  }

                  {
                    showDivison  && (selectedReportType?.id === "D010" || selectedReportType?.id === "D003"||  selectedReportType?.id === "D005" || selectedReportType?.id === "D006" || selectedReportType?.id === "D002" || selectedReportType?.id === "D001") && selectedReportType?.id !== "D004"  && selectedReportType?.id !== "E003" ? 
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">
                        {"Select Division"} <span style={{color: '#FF4C4C'}}>*</span>
                      </div>
                      <LargeDropdown
                        data={divisionData}
                        value={division?.label ? division.label : "Select..."}
                        selectedValue={(data) => handleDivision(data)}
                      />
                    </div>
                    :
                    null
                  }

                 {
                    showAgency || (selectedReportType?.id === "E004") ? 
                    <div className="flex-direction-column ml-3">
                       <div className="p-sub-heading ml-2">
                        {"Select Agency"}
                      </div>
                      <LargeDropdown
                          data={selectedReportType?.id === "E004" ? agencyList : agencyData}
                          value={agency?.label ? agency?.label : 'Select..' }
                          selectedValue={(data) => handleAgency(data)}
                      />
                    </div>
                    :
                    null
                  } 

                  {showReportStatus  ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">{"Status"}</div>
                        <Select
                            isMulti
                            options={statusList}
                            value={statusFilter}
                            onChange={handleStatusFilter}
                            className="basic-multi-select"
                            placeholder="Select"
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                        />
                    </div>
                  ) : null}

                </div>
                <div>
                  <button
                    className="btn buttonStyle px-20px m-10px"
                    onClick={downloadReport}
                    disabled={checkValidity()}
                  >
                    Generate Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuggestionSchemeReport;
